.calendarContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 3px;
  max-height: 1200px;
  max-width: 540px;
  margin-top: 20px;
  font-family: 'nw-primary';
  min-height: 400px;
}
.mobileCalendarContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow-x: hidden;
  padding: 3px;
  margin-top: 20px;
  font-family: 'nw-primary';
}

.calendarContent {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  min-height: 189px;
  /* overflow-y: auto; */
  margin: 3px;
  display: block;
}

.noScrollingCalendar {
  background-color: white;
  margin-right: 5px;
  position: relative;
  border-top: 1px solid rgb(188, 189, 188);
}
.scrollingCalendar {
  overflow-y: hidden;
  max-height: 900px;
  background-color: white;
  margin-right: 5px;
  position: relative;
  border-top: 1px solid rgb(188, 189, 188);
  min-height: 270px;
}

.calendar-refresh {
  display: block;
  cursor: pointer;
  font-family: 'nw-primary';
  float: right;
  margin-right: 25px;
}

.calendarChevronContainer {
  display: inline-block;
  overflow: hidden;
  /* height: 54px; */
  width: 30px;
  padding-top: 10px;
}

.calendarData {
  display: inline-block;
  /* border-right: 1px solid #d3d3d3; */
  /* width: 320px; */
  /* height: 54px; */
  overflow: hidden;
  margin-top: 2px;
  font-size: 16px;
  text-align: left;
  color: #1c57a5;
  font-weight: bold;
  padding-top: 10px;
  font-family: 'nw-primary';
  width: 238px;
}

.mobileCalendarDisplay {
  padding-bottom: 15px;
  padding-left: 5px;
}
.calendarDisplay {
  padding-bottom: 15px;
  padding-left: 5px;
  /* overflow-y: auto; */
}
.noScrollCalendar {
  overflow-y: visible;
}
.scrollCalendar {
  overflow-y: auto;
}
.scrollCalendar::-webkit-scrollbar {
  width: 12px;
}

.scrollCalendar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollCalendar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}

.calendarRowHead1 {
  font-size: 11px;
  font-weight: bold;
  color: #1c57a5;
  display: inline-block;
  width: 115px;
  padding-bottom: 5px;
  font-family: 'nw-primary';
}
.calendarRowHead2 {
  font-size: 11px;
  font-weight: bold;
  color: #1c57a5;
  display: inline-block;
  padding-bottom: 5px;
  font-family: 'nw-primary';
}

.calendarDate {
  font-size: 16px;
  display: inline-block;

  color: #4d4f53;
  font-weight: bold;
  font-family: 'nw-primary';
  vertical-align: middle;
  width: 115px;
}

.calendarName {
  font-size: 13px;
  display: inline-block;
  color: #4d4f53;
  font-family: 'nw-primary';
  vertical-align: middle;
  width: 116px;
}
.bravoButton {
  margin-left: 8px;
  width: 130px;
  padding-left: 30px;
}
@media (min-width: 400px) {
  .calendarRowHead1 {
    width: 120px;
  }
  .calendarDate {
    width: 120px;
  }
  .calendarName {
    width: 125px;
  }
  .calendarData {
    width: 260px;
  }
  .bravoButton {
    margin-left: 48px;
  }
}

@media (min-width: 450px) {
  .calendarRowHead1 {
    width: 150px;
  }
  .calendarDate {
    width: 150px;
  }
  .calendarName {
    width: 150px;
  }
  .calendarData {
    width: 300px;
  }
  .bravoButton {
    margin-left: 45px;
  }
}

@media (min-width: 500px) {
  .calendarName {
    width: 200px;
  }
  .calendarData {
    width: 350px;
  }
  .bravoButton {
    margin-left: 65px;
  }
}

.workdayCalendarButton {
  margin-left: 12px;
  width: 168px;
  padding-left: 30px;
}

@media (min-width: 576px) {
  .bravoButton {
    margin-left: 80px;
  }
  .calendarName {
    width: 250px;
  }
}
@media (min-width: 765px) {
  .bravoButton {
    margin-left: 165px;
  }
}

.dateRangeSelect {
  font-family: 'nw-primary';
  font-size: 14px;
  color: #4d4f53;
  width: 156px;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border: transparent;
  border-bottom: 1px solid #6c6c6c;
  height: 31px;
  padding-left: 13px;
  line-height: 17px;
}

.calendarBravoText {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  color: #4d4f53;
  margin-right: 25px;
}
