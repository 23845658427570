.recognitionContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 3px;
  /* height: 525px; */
  max-width: 540px;
  margin-top: 20px;
}

.mobileRecognitionContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow-x: hidden;
  padding: 3px;
  margin-top: 20px;
}

.recognitionContent {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  min-height: 189px;
  /* overflow-y: auto; */
  margin: 3px;
  display: block;
}

.recognition-tile-header {
  color: #1c57a5;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  /* padding-left: 25px; */
  padding-top: 10px;
  padding-bottom: 9.23px;
  font-family: 'nw-primary';
  display: inline-block;
}

.RecognitionData1 {
  display: inline-block;
  /* border-right: 1px solid #d3d3d3; */
  /* width: 175px; */
  /* height: 115px; */
  overflow: hidden;
  margin-top: 6px;
  font-size: 18px;
  text-align: left;
  color: #1c57a5;
  font-weight: bold;
  vertical-align: top;
  padding-right: 40px !important;
  float: inline-start;
}

.RecognitionData {
  display: inline-block;
  /* border-right: 1px solid #d3d3d3; */
  /* width: 175px; */
  /* height: 115px; */
  /* overflow: hidden; */
  margin-top: 12px;
  font-size: 18px;
  text-align: left;
  color: #1c57a5;
  font-weight: bold;
  vertical-align: top;
  padding-right: 25px;
}

.RecognitionDataDisplay {
  /* display: inline-block; */
  font-size: 18px;
  text-align: left;
  color: #1c57a5;
  font-weight: bold;
  vertical-align: top;
  padding-right: 25px;
  margin-top: 4px;
}
.RecPlainText {
  font-size: 13px;
  display: inline-block;
  color: #4d4f53;
  font-family: 'nw-primary';
  /* padding-left: 25px; */
}

.recBravoButton {
  /* margin-left: 65%; */
  width: 141px;
  padding-left: 30px;
}

.teamRecChevronContainer {
  display: inline-block;
  overflow: hidden;
  height: 45px;
  width: 30px;
  padding-top: 10px;
}

.RecImageContainer {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  margin-top: 8px;
  width: 25%;
}

.RecImage {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  border: lightgrey solid 1px;
}

.learnMoreLink {
  font-size: 13px;
  text-decoration: underline !important;
  border: none;
  background-color: transparent;
  color: #1c57a5;
  /* margin-right: 38px; */
}

.BravoImageContainer {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  margin-top: 8px;
  padding-left: 15px;
}

.bravoImage {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  border: lightgrey solid 1px;
}
