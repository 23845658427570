.leaderContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  max-width: 540px;
  /* max-width: 496px; */
  display: block;
  overflow: hidden;
  padding: 3px;
  /* height: 525px; */
  min-height: 624px;
  margin-top: 20px;
}

.mobileLeaderContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow-x: hidden;
  padding: 3px;
  margin-top: 20px;
}

.leaderContent {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  min-height: 600px;
  /* overflow-y: auto; */
  margin: 3px;
  display: block;
}

.ldTitle {
  color: #1c57a5 !important;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  padding-top: 10px;
  font-family: 'nw-primary';
  /* display: inline-block; */
}

.ldBlueBanner {
  background-color: #1c57a5 !important;
  border-radius: 6px;
  width: 100%;
  height: 53px;
  /* display: inline-block; */
}

.ldBlueBannerMobile {
  background-color: #1c57a5 !important;
  border-radius: 6px;
  width: 100%;
  height: 80px;
}

.ldBellImg {
  width: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.newsLinksContainer {
  margin-left: 55px;
}

.newsLinksContainerMobile {
  margin-left: 15px;
}

.ldNewsLink {
  font-family: 'nw-primary';
  color: #1c57a5 !important;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 12px;
  cursor: pointer !important;
  margin-left: 14px;
  border: none !important;
  background: none !important;
}

.linkbutton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.ldRefLink {
  font-family: 'nw-primary';
  color: #1c57a5;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.ldRefLinkMobile {
  font-family: 'nw-primary';
  color: #1c57a5;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.dotted {
  border: 1px dotted gray;
  border-style: none none dotted;
  color: #fff;
  background-color: #fff;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.refContainer {
  margin-left: 55px;
  margin-top: 15px;
}

.refContainerMobile {
  margin-left: 5px;
  margin-top: 15px;
}

.refTitle {
  font-family: 'nw-primary';
  color: #1c57a5 !important;
  font-size: 16px;
  font-weight: bold;
  padding-top: 3px;
}

.refTitleMobile {
  font-family: 'nw-primary';
  color: #1c57a5 !important;
  font-size: 14px;
  font-weight: bold;
  padding-top: 3px;
}

.ldChevronContainer {
  display: inline-block;
  overflow: hidden;
  height: 25px;
  width: 30px;
  margin-right: 10px;
}

.ldOpenRefContainer {
  margin-left: 38px;
}

.ldOpenRefContainerMobile {
  margin-left: 15px;
}

.ldDescText {
  font-family: 'nw-primary';
  color: #4d4f53 !important;
  font-size: 14px !important;
  padding-bottom: 8px;
}

.ldLinkImage {
  display: inline-block;
  background-image: url('/external-link.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 25px;
  cursor: pointer;
}

.ldLinkImageMobile {
  display: inline-block;
  background-image: url('/external-link.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 20px;
  cursor: pointer;
}

.ldTopBannerImage {
  /* display: inline-block; */
  background-image: url('/speech_icon.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  width: 44px;
  height: 53px;
  margin-left: 25px;
}

.ldBottomBannerImage {
  /* display: inline-block; */
  background-image: url('/link_ref.svg');
  background-position-y: center;
  background-repeat: no-repeat;
  width: 44px;
  height: 53px;
  margin-left: 25px;
}

.ldWhiteTextBig {
  font-family: 'nw-primary';
  color: #ffffff;
  font-size: 16px;
  padding-top: 5px;
}

.ldWhiteTextSmall {
  font-family: 'nw-primary';
  color: #ffffff !important;
  font-size: 14px;
  /* padding-top: 5px; */
}

.ldWhiteTextSmallMobile {
  font-family: 'nw-primary';
  color: #ffffff !important;
  font-size: 14px;
  text-decoration: underline;
  /* padding-top: 5px; */
}

.ldNewLogo {
  background-image: url('/NewLogo.svg') !important;
  background-position-y: bottom;
  background-repeat: no-repeat;
  height: 36px;
  width: 47px;
  margin-left: 8px;
}

.adminButton {
  font-family: 'nw-primary';
  color: #1c57a5 !important;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 25px 10px 10px;
}

.titleLine {
  display: inline-block;
}

.adminBackground {
  max-width: 1168px;
  min-height: 1050px;
  border-radius: 20px;
  opacity: 1;
  background-color: #ffffff;
  margin: 0 auto;
}

.adminTile {
  font-family: 'nw-primary';
  font-size: 31px !important;
  color: #1c57a5 !important;
  /* padding: 20px 24px; */
  padding-left: 24px;
  padding-top: 20px;
}

.adminTile2 {
  font-family: 'nw-primary';
  font-size: 31px !important;
  color: #4d4f53 !important;
  padding-top: 20px;
  padding-left: 10px;
}

.adTopHelpText {
  font-family: 'nw-primary';
  font-size: 14px !important;
  color: #4d4f53 !important;
  padding-left: 10px;
}

.needToKnowCont {
  margin-left: 95px !important;
}

.needToKnowTitle {
  font-family: 'nw-primary';
  font-size: 18px !important;
  color: #1c57a5 !important;
}

.linkSubTitle {
  font-family: 'nw-primary';
  font-size: 11px !important;
  color: #1c57a5 !important;
  padding-top: 18px;
}

.infoText {
  font-family: 'nw-primary';
  font-size: 14px !important;
  color: #4d4f53 !important;
  padding-top: 5px;
  padding-right: 8px;
}

.inputBox {
  background-color: #f6f6f6 !important;
  width: 328px;
  height: 34px;
  border: none;
}

.inputBoxText {
  font-family: 'nw-primary';
  font-size: 14px !important;
  color: #4d4f53 !important;
  padding-left: 7px;
  padding-top: 5px;
}

.deleteButton {
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  width: 94px;
  height: 36px;
  margin-left: 14px;
  cursor: pointer;
  background-image: url('/delete-icon.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12%;
}

.deleteButtonText {
  font-family: 'nw-primary';
  font-size: 14px !important;
  color: #1c57a5 !important;
  padding-top: 7px;
  padding-left: 25px;
}

.newLinkButton {
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  width: 142px;
  height: 36px;
  margin: auto;
  cursor: pointer;
  background-image: url('/Add-icon.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8%;
}

.dottedLine {
  border: 1px dotted gray;
  border-style: none none dotted;
  color: #fff;
  background-color: #fff;
  margin-bottom: 20px;
}

.collapseHeader {
  font-family: 'nw-primary';
  font-size: 18px !important;
  color: #1c57a5 !important;
  padding-left: 5px;
}

.summaryTextInputBox {
  background-color: #f6f6f6 !important;
  width: 783px;
  height: 34px;
  border: none;
}

.remainingCharacters2 {
  font-family: 'nw-primary';
  font-size: 12px !important;
  color: #4d4f53 !important;
  padding-left: 22%;
}

.remainingCharacters3 {
  font-family: 'nw-primary';
  font-size: 12px !important;
  color: #4d4f53 !important;
  padding-left: 14%;
}

.remainingCharacters {
  font-family: 'nw-primary';
  font-size: 12px !important;
  color: #4d4f53 !important;
}

.cancelButton {
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  width: 80px;
  height: 38px;
  margin-left: 14px;
  cursor: pointer;
}

.cancelButtonText {
  font-family: 'nw-primary';
  font-size: 14px !important;
  color: #1c57a5 !important;
  padding-top: 8px;
  padding-left: 15px;
}

.cancelButtonText:hover {
  font-weight: bold;
}

.saveButton {
  background-color: #1c57a5;
  border-radius: 7px;
  opacity: 1;
  width: 145px;
  height: 38px;
  margin-left: 14px;
  border: none;
}

.saveButtonText {
  font-family: 'nw-primary';
  font-size: 14px;
  color: #ffffff;
  /* padding-left: 14px !important; */
  cursor: pointer;
}

.saveButtonText:hover {
  font-weight: bold;
}

.activeBox {
  height: 36px;
  background-image: url('/completed.png');
  background-repeat: no-repeat;
  background-position-x: left;
  border: 1.5px solid green !important;
  width: 36px;
  margin-top: 5px;
  border-radius: 4px;
}

.inactiveBox {
  height: 36px;
  background-image: url('/incomplete.png');
  background-repeat: no-repeat;
  background-position-x: left;
  border: 1px solid black !important;
  width: 36px;
  margin-top: 5px;
  border-radius: 4px;
}

.datebox {
  border: 1px solid #1c57a5 !important;
  border-radius: 4px;
  width: fit-content;
}
