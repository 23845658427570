.background {
  background-color: rgb(255, 255, 255);

  }

  .jobChangeContainer::-webkit-scrollbar {
  width: 10px;
}

.jobChangeContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
}

.jobChangeContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}

.mobileJobChangeContainer::-webkit-scrollbar {
  width: 10px;
}

.mobileJobChangeContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
}

.mobileJobChangeContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}
.jobChangeContainer {
  background-color: rgb(255, 255, 255);
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  max-width: 1200px;
  /* max-width: 496px; */
  display: block;
  overflow: hidden auto;
  padding: 3px;
  height: 500px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  }

.mobileJobChangeContainer {
  background-color: rgb(255, 255, 255);
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow: hidden auto;
  min-height: 400px;
  max-height: 800px;
  padding: 3px;
  margin-top: 20px;
  }
  .checklistBar {
    background: #1553a5;
  }

.jobChangeContent {
  border-radius: 12px 12px 12px 12px;
  background-color: rgb(168, 125, 6);
  min-height: 600px;
  overflow-y: auto; 
  margin: 1px;
  display: block;
  }

.jobChangeTitle {
  color: #f9fbfd !important;
  font-size: 32px;
  text-align: left;
  letter-spacing: 0px;
  padding-top: 10px;
  font-family: 'nw-primary';
  /* display: inline-block; */
  }

.jobChangeSubTitle {
  color: #000103 !important;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  padding-top: 10px;
  font-family: 'nw-primary';
  /* display: inline-block; */
  }

.sectionSeparator {
  height: 1px;
  width: 1175px;
  background-color: rgb(0, 0, 0);
  border: none;
  }
  
.smallSeparator {
  height: 1px;
  width: 1100px;
  background-color: rgb(0, 0, 0);
  border: none;
  }

  .linkHeaders {
    color: #000103 !important;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  padding-top: 10px;
  font-family: 'nw-primary';
  /* display: inline-block; */
  }
  th {
      font-family: 'nw-primary';
      text-align: left;
      text-indent: 20px;
      font-size: 16px;
      background-color: rgb(255, 255, 255);
  }
  td {
      font-family: 'nw-primary';
      font-size: 16px;
      text-align: right;
      background-color: rgb(255, 255, 255);
  }

  a.checkListLinks:link{
    text-decoration: underline;
  }

  .clLinkImage {
      display: inline-block;
      background-image: url('/external-link.svg');
      background-position-y: center;
      background-repeat: no-repeat;
      width: 15px;
      height: 10px;
      cursor: pointer;
    }
    
    .clLinkImageMobile {
      display: inline-block;
      background-image: url('/external-link.svg');
      background-position-y: center;
      background-repeat: no-repeat;
      width: 15px;
      height: 20px;
      cursor: pointer;
    }

    .checklistCloseButton {
      background-color: transparent !important;
      background-image: url(/closeChecklist.png);
      height: 34px;
      width: 34px;
      position: absolute;
      top: 4px;
      left: calc(100% - 54px);
    }
    
    .checkListMobilePopupContainer {
      font-family: "nw-primary";
      z-index: 100;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgb(31, 11, 68);
      border-radius: 12px 12px 12px 12px;
      display: block;
      padding: 3px;
      width: 100%;
      height: 100%;
  }
  .checkListMobilePopupContainer::-webkit-scrollbar {
    width: 16px;
  }

  .checklistPopupContainer {
      font-family: "nw-primary";
      z-index: 100;
      position: fixed;
      width: 1200px;
      height: auto;
      top: 400px;
      left: 27%;
      margin: -365px 0 0px -150px;
      /*margin-left: auto;
      margin-right: auto;*/
      background-color: rgb(28, 87, 165);
      display: block;
      padding: 8px;
    }

    .checkListPopupContainer::-webkit-scrollbar {
      width: 16px;
    }

    .checklistCloseButton {
      background-color: transparent !important;
      background-image: url(/closeChecklist.png);
      height: 34px;
      width: 34px;
      position: absolute;
      top: 4px;
      left: calc(100% - 54px);
    }

    .checklistTitle {
      color: #ffffff !important;
      font-size: 32px;
      text-align: left;
      letter-spacing: 0px;
      padding-top: 6px;
      font-family: 'nw-primary';
    }
    .overlay {
      position: fixed; /* Positioning and size */
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(105, 31, 31, 0.5); /* color */
      display: none; /* making it hidden by default */
    }
    .noOverlay {
      position: fixed; /* Positioning and size */
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(128,128,128,0.5); /* color */
      display: block; /* making it viewable */
    }

    @media screen and (max-width: 1450px) {
      .checklistPopupContainer {
        margin-left: -125px;
        /*margin: -250px 0 0 -350px; */
        width: 900px;
        background-color: rgb(28, 87, 165);
      }
      
      .jobChangeTitle {
        /* display: grid;
            grid-template-columns: auto 317px; */
        width: calc(100% - 20px);
        font-family: "nw-primary";
        font-size: 1.1rem;
        margin: 20px 23px 16px 23px;
        position: relative;
        top: 0;
        left: 0;
      }
    }
    .clChevronContainer {
      display: inline-block;
      overflow: hidden;
      height: 25px;
      width: 30px;
      margin-right: 10px;
    }

   