.linksbar {
  background-image: url('/repeating-pattern-LeaderConnect.png');
  background-color: #1a4e8e;
  width: 100%;
  background-repeat: repeat;
  min-height: 70px;
  /* max-height: 109px; */
  overflow-y: hidden;
  overflow-x: auto;
}
.linksbar .container {
  min-width: 1016px !important;
}

.linkButton {
  top: 33px;
  left: 138px;
  width: 154px;
  height: 49px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 3px;
  border-color: white;
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

@media (min-width: 577px) {
  .linksbar {
    /* background-image: url("/repeating-pattern-tools.jpg"); */
    width: 100%;
    background-repeat: repeat;

    overflow-x: unset;
    overflow-y: unset;
  }
  .linksbar .container {
    min-width: 100% !important;
  }
  .linkButton {
    margin-top: 33px;
    /* margin-right: 16px; */
    margin-left: 5px;
    /* width: 170px; */
  }
}

@media (min-width: 579px) {
  .linksbar {
    /* height: 250px; */
    text-align: center;
  }
  .linksmargin {
    margin-left: -px;
  }
}

@media (min-width: 1193px) {
  .linksbar {
    /* background-image: url("/repeating-pattern-tools.jpg"); */
    width: 100%;
    background-repeat: repeat;
    height: 109px;
    overflow-x: unset;
    overflow-y: unset;
  }
}

@media (min-width: 1200px) {
  .linksmargin {
    margin-left: -15px;
  }
}
.linksbar .container {
  min-width: 100% !important;
}

.concurLinkImage {
  padding-top: 7px;
  background-image: url('/concur.jpg');
  background-repeat: no-repeat;
  background-size: 150px 47px;
  margin-left: 5px;
}

.concurOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.concurLinkImage:hover .concurOverlay {
  opacity: 1;
}

@media (min-width: 1207px) {
  .workdayLinkImage {
    margin-left: 14px;
  }
}
.aribaLinkImage {
  padding-top: 7px;
  background-image: url('/ariba.jpg');
  background-repeat: no-repeat;
  background-size: 150px 47px;
}

.aribaOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.aribaLinkImage:hover .aribaOverlay {
  opacity: 1;
}

.workdayLinkImage {
  /* width: 75px; */
  padding-top: 7px;
  background-image: url('/workday.jpg');
  background-repeat: no-repeat;
  background-size: 150px 47px;
}

.workdayOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.workdayLinkImage:hover .workdayOverlay {
  opacity: 1;
}

.egenciaLinkImage {
  padding-top: 7px;
  background-image: url('/egencia.jpg');
  background-repeat: no-repeat;
  background-size: 150px 47px;
}


.egenciaOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.egenciaLinkImage:hover .egenciaOverlay {
  opacity: 1;
}

.bravoLinkImage {
  padding-top: 7px;
  background-image: url('/bravo.jpg');
  background-repeat: no-repeat;
  background-size: 150px 47px;
}

.bravoOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.bravoLinkImage:hover .bravoOverlay {
  opacity: 1;
}

.iiqLinkImage {
  padding-top: 7px;
  background-image: url('/iiq.jpg');
  background-repeat: no-repeat;
  background-size: 150px 47px;
}

.iiqOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.iiqLinkImage:hover .iiqOverlay {
  opacity: 1;
}

.mytechLinkImage {
  padding-top: 7px;
  background-image: url('/MyTech.jpg');
  background-repeat: no-repeat;
  background-size: 148px 47px;
}


.mytechOverlay {
  position: relative;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: 'nw-primary';
  font-size: 10.5px;
  padding-left: 5px;
  padding-right: 5px;
}

.mytechLinkImage:hover .mytechOverlay {
  opacity: 1;
}
