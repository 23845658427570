.idrive_course {
  border-bottom: grey solid 1px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.idrive_title {
  font-size: 10px;
  color: #007d8a;
  text-transform: uppercase;
}

.idrive_description {
  font-size: 20px;
}
