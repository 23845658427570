*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: 'nw-primary';
}

button {
  font-family: 'nw-primary';
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.md-card {
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  font-family: 'nw-primary';
  font-size: 20px;
  line-height: 28.5667px;
  margin-bottom: 8px;
  /* margin-left: 8px; */
  margin-right: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  cursor: pointer;
  min-height: 100px;
}

.md-card:hover {
  background-color: lightgray;
}

.md-card-title {
  font-family: 'Segoe UI Web (West European)', Segoe UI, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #4d4f53;
  margin: 6px 0;
  overflow: hidden;
  line-height: 1.4em;
  max-height: 3.5em;
}

.md-card-text {
  font-size: 16px;

  word-wrap: break-word;
  max-height: 4em;
  overflow: hidden;
  margin-bottom: 5px;
}

.page-item:first-child .page-link::after {
  content: '' !important;
}

.page-item:first-child .page-link::before {
  content: '‹';
}

/* .snComponentTextInput {
  display: inline-block;
  height: 40px;
  width: 357px;
  font-size: 16px;
  color: rgb(51, 51, 51);
  border: none;
  border-bottom: 2px solid #e2e3e5;
  border-right: 2px solid #e2e3e5;
  padding: 0.375rem 0.75rem;
} */

/* .snComponentButton {
  display: inline-block;
  background-color: white;
  border: none;
  vertical-align: top;
  height: 40px;
  width: 48px;
  border-bottom: 2px solid #e2e3e5;
} */

.addRemoveButton {
  display: inline-block;
  background-color: transparent;
  border: none;
  vertical-align: top;
  height: 48px;
  width: 48px;
  margin-left: 1px;
}

.portal {
  border: 1px solid black;
  display: block;
  height: 300px;
  overflow: auto;
}

.portal-container {
  margin: 20px;
}

.portal-title {
  color: white;
  text-align: center;
  background-image: linear-gradient(100deg, rgb(0, 92, 185), rgb(8, 117, 225));
}

.menubar {
  flex-direction: row !important;
  background-color: #ff9c02;
  left: 0px;
  width: 100%;
  /* padding-left: 20px; */
  color: rgb(1, 95, 189);
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex;
  padding: 0.5rem 1rem;
}
.menubar a {
  display: inline-block;
  padding-left: 1rem;
  cursor: pointer;
  color: #093f65;
}
.menubar a:hover {
  color: #093fc6;
}

.tool_27f0c5f3 {
  box-sizing: border-box;
  border: 1px solid #bcbdbc;
  width: 100%;
  height: 50px;
  margin: 6px 5px;
  padding: 4px;
  color: #4d4f53;
  text-decoration: none !important;
  text-align: left;
  display: inline-block;
  position: relative;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
    BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.toolLink_27f0c5f3 {
  text-align: left;
  cursor: pointer;
}

.toolLink_27f0c5f3 img {
  width: 30px;
  height: 30px;
  margin: 5px;
  margin-right: 5px;
  margin-right: 7px;
  vertical-align: middle;
  border: 0;
}

.toolLink_27f0c5f3 strong {
  height: 24px;
  font-size: 18px;
  font-weight: 600;
  color: #4d4f53;
  position: absolute;
  top: 20%;
  font-family: 'Segoe UI Web (West European)', Segoe UI, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.wdlogo {
  display: block;
  width: 36px;
  height: 35px;
  background: #ffffff;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  background-image: url('/wdsquare.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.clickable {
  cursor: pointer;
}

button.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block !important;
  opacity: 100 !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -10px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  height: 157px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0%;
  margin-left: 5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.pageCover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.dateSelectContainer {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.portletSelectContainer {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  height: 90%;
  width: 90%;
}

.card {
  margin: 10px;
  height: 300px;
  overflow: hidden;
  border-radius: 1.25rem !important;
}

.buttonlink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.row-striped:nth-of-type(odd) {
  background-color: #efefef;
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
}

.portletSelectButtonActive {
  width: 100%;
  max-width: 300px;
  color: black;
  background-color: #e2f0d9 !important;
  background-image: url('/icon-check.svg');
  background-repeat: no-repeat;
  background-position: right;
  /* margin-top: 10px; */
  height: 46px;
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.portletSelectButtonInactive {
  width: 100%;
  max-width: 300px;
  color: black;
  background-image: url('/icon-plus.svg');
  background-repeat: no-repeat;
  background-position: right;
  /* margin-top: 10px; */
  height: 46px;
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.cardContainer {
  display: inline-block;
}

@media (min-width: 250px) {
  .md-card {
    /* margin-left: 8px !important; */
    width: 97%;
  }

  .md-card-people {
    /* margin-left: 8px !important; */
    width: 97%;
  }

  .LoadResults {
    margin-left: 0px !important;
  }

  /* .NeedHelpDiv {
    max-width: 200px;
  } */

  /* .snComponentTextInput {
    width: 127px;
  } */
}

@media (min-width: 300px) {
  .md-card {
    /* margin-left: 8px !important; */
    width: 97%;
  }

  .md-card-people {
    /* margin-left: 8px !important; */
    /* max-width: 270px !important; */
    width: 97%;
  }

  .LoadResults {
    margin-left: 10px !important;
  }

  /* .snComponentTextInput {
    width: 127px;
  } */
}

@media (min-width: 360px) {
  .md-card {
    /* margin-left: 8px !important; */
    width: 97%;
  }

  .md-card-people {
    /* margin-left: 8px !important; */
    max-width: 97%;
  }

  /* .snComponentTextInput {
    width: 167px;
  } */
  .cardContainer {
    width: 100%;
  }
}

@media (min-width: 400px) {
  .md-card {
    /* margin-left: 8px !important; */
    width: 97%;
  }

  .md-card-people {
    /* margin-left: 8px !important; */
    width: 97%;
  }

  .LoadResults {
    margin-left: 20px !important;
  }

  /* .snComponentTextInput {
    width: 215px;
  } */
}

@media (min-width: 500px) {
  .cardContainer {
    width: 100%;
  }

  .md-card-people {
    /* margin-left: 8px !important; */
    width: 100%;
  }

  .LoadResults {
    margin-left: 130px !important;
  }
}

@media (min-width: 800px) {
  .cardContainer {
    width: 50%;
  }

  .LoadResults {
    margin-left: 250px !important;
  }
}

@media (min-width: 1500px) {
  .cardContainer {
    width: 33%;
  }
}

.numberCell {
  text-align: right;
}

.drag-container {
  text-align: center;
}

.movebtn {
  background-color: white;
}
.movebtn:disabled {
  background-color: lightgray !important;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.announcementDefaultHeight {
  height: 248px;
}

.modalTitleBar {
  background-color: gray;
  width: 100%;
  display: inline-block;
  color: white;
  font-family: Verdana, Arial;
  font-size: 11px;
  font-weight: bold;
  height: 22px;
  top: -2px;
  position: relative;
  text-align: center;
}

.modalEditPage {
  background-color: white;
  overflow: hidden auto;
  display: inline-block;
  height: 90%;
  width: 100%;
  position: relative;
  top: 4px;
}

.close {
  float: right;
  padding-left: 10px;
  font-size: 1.4rem !important;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  width: 100%; /* Full-width */
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.drag-container {
  text-align: center;
}

.draggable {
  width: 100px;
  height: 100px;
  background-color: yellow;
  margin: 10px auto;
}

.portletList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.portletList li {
  /* padding: 10px 20px; */
  position: relative;
  display: flex;
  align-items: flex-start;
  line-height: 1;
  min-width: 300px;
}

.portletList li .drag {
  /* margin-right: 15px; */
  cursor: move;
}

.portletListActive {
  background-color: white;
}

.portletListInctive {
  background-color: white;
}

.mover {
  background-image: url('/canMove.png');
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  height: 46px !important;
  width: 48px !important;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-right: none;
  border-color: #326295;
}

.mover:hover {
  background-color: lightgray;
}

.obfuscate {
  filter: blur(5px);
  margin: 5px;
}

.obfuscateMore {
  filter: blur(15px);
  margin: 5px;
}

@media only screen and (max-width: 600px) {
  .header-row {
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .header-dropdowns {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .header-menu-item {
    flex-basis: 100%;
    width: 100%;
  }
  .snComponentSearchForm {
    flex-basis: 100%;
    margin-bottom: 16px;
  }

  /* .snComponentTextInput {
    flex-basis: 100%;
  } */

  .no-chevron {
    height: 60px;
  }
}

#dropdown-item-button button.no-chevron {
  width: 100%;
  background-color: transparent;
  color: white;
  border-radius: 0;
  border: none;
  /* border-bottom: 2px solid #e2e3e5; */
  box-shadow: none;
  outline: none;
}

#dropdown-item-button:nth-child(5) .no-chevron {
  border-right: 2px solid #e2e3e5 !important;
}

#dropdown-item-button button.no-chevron:hover,
.active-menu-item {
  /* background-color: #eeeeef !important; */
  color: #237d8a !important;
  border: none;
  border-radius: 0;
}

.active-menu-item {
  background-color: #ffffff !important;
  /* border-bottom: 2px solid #1c57a5 !important; */
  border-bottom: 2px solid #ffffff !important;
}

button.no-chevron::after {
  content: none;
}

.dropdown-menu-custom-style {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

#dropdown-item-button .dropdown-menu-custom-style {
  min-width: 265px;
  top: -3px !important;
  left: -1px !important;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  /* box-shadow: none; */
  width: 100%;
  transform: translate3d(0px, 41px, 0px) !important;
}

.displayurls {
  display: block;
}

.pageHeader {
  /* font: Bold 31px/37px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 31px;
  line-height: 37px;
  letter-spacing: 0px;
  text-align: left;
}

.pageHeader button {
  color: #1c57a5;
}

.instructions {
  /* font: normal 14px/17px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.instructions ol {
  /* font: normal 14px/22px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}

.bolt-textfield-wc--input.bolt-textfield {
  background: #f6f6f6;
  border: 0;
  box-shadow: inset 0 -2px 0 0 #6c6c6c;
  box-sizing: border-box;
  color: #222;
  font-family: 'nw-primary';
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin: 0;
  padding: 0.75rem;
  text-decoration: none;
  transition: background 350ms, border 350ms, color 350ms, box-shadow 350ms;
  width: 100%;
  z-index: 1;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Upload' !important;
  color: #fff;
  background-color: #284f78;
  border-color: #25496f;
}

.custom-file-label {
  border-radius: 0.25rem 0.25rem 0.25rem 0px !important;
}

.charCounter {
  /* font: Regular 12px/22px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #4d4f53;
}

.btn-light {
  background-color: white !important;
  color: #326295 !important;
}

.btn-light:hover {
  background-color: #326295 !important;
  color: #fbfbfb !important;
}

.NeedHelpDiv {
  /* font: Bold 16px/20px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 15px;
}
.NeedHelpButton {
  height: 36px;
  width: 158px;
  font-family: 'nw-primary';
  font-size: 14px !important;
  line-height: 17px !important;
  padding-top: 9px !important;
  margin-bottom: 20px;
}

.LoadResults {
  font-family: 'nw-primary';
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 9px !important;
  margin-left: 250px;
  background: #1c57a5 0% 0% no-repeat padding-box !important;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
}

.resultArticle {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000003e;
  opacity: 1;
  margin-right: 15px;
}

.articleTitle {
  text-align: left;
  /* font: Bold 18px/22px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
}

.articleText {
  text-align: left;
  /* font: 14px/17px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
}

.searchTitle {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  /* font: 20px/24px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #007d8a;
  opacity: 1;
}

.searchTitleLine {
  border-bottom-width: 1px;
  border-bottom-color: #707070;
  border-bottom-style: solid;
  margin-bottom: 10px;
}

.searchSubLine {
  width: 900px;
  border-bottom-width: 1px;
  border-bottom-color: #707070;
  border-bottom-style: solid;
  margin-bottom: 10px;
}

.searchTitleWords {
  text-align: left;
  /* font: Bold 31px/37px Proxima Nova; */
  font-family: 'nw-primary';
  /* font-weight: bold; */
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #003b5c;
  opacity: 1;
}

.searchFilterWords {
  text-align: left;
  /* font: Bold 31px/37px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  padding-bottom: 20px;
  opacity: 1;
  cursor: pointer;
  height: 29px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-top: 4px;
}

.searchCount {
  height: 40px;
  /* width: 100%; */
  text-align: left;
  /* font: 14px/17px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  padding-left: 11px;
  /* border-top-width: 1px; */
  /* border-bottom-width: 1px; */
  /* border-top-color: #707070; */
  /* border-bottom-color: #707070; */
  /* border-top-style: solid; */
  /* border-bottom-style: solid; */
  display: block;
  padding-top: 20px;
}

.backToTop {
  /* font: Bold 14px/22px Proxima Nova; */
  font-family: 'nw-primary';
  padding-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
  background-image: url('/bolt-chevron-up.svg');
  background-repeat: no-repeat;
  background-position: left;
  min-width: 90px;
  text-align: right;
  background-size: 20px 11px;
}

.backToTop:hover {
  color: #326295;
  text-decoration: underline;
}

.awsSubTitle {
  text-align: left;
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  padding-left: 25px;
}

.awsData {
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  padding-left: 50px;
  padding-bottom: 5px;
}

.awsDataError {
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  padding-left: 10px;

  border-color: red;
  border-style: solid;
  border-width: 1px;
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.awsAppointmentLine {
  text-align: left;
  font-family: 'nw-primary';
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  padding-left: 50px;
}
.awsAppointmentPhone {
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
}

.portletError {
  text-align: center;
  padding: 17px;
}
.portletErrorAlert {
  background-color: #d32426;
  display: block;
  height: 34px;
  width: 34px;
  color: white;
  font-family: 'nw-primary';
  font-size: 24px;
  border-radius: 17px 17px 17px 17px;
  margin-left: 230px;
}

.smallPortletErrorAlert {
  background-color: #d32426;
  display: inline-block;
  height: 18px;
  width: 18px;
  color: white;
  font-family: 'nw-primary';
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  border-radius: 9px 9px 9px 9px;
}

.expandPeople {
  color: #1c57a5;
  opacity: 1;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 25px;
  margin-left: 20px;
}

.md-card-people {
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  /* width: "100%"; */
  flex-direction: column;
  font-family: 'nw-primary';
  font-size: 20px;
  line-height: 28.5667px;
  margin-bottom: 8px;
  /* margin-left: 8px; */
  margin-right: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  min-height: 82px;
}

/* .md-card-people:hover{
  background-color: lightgray;
} */

.people-image {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  margin-top: 8px;
  width: 50px;
  height: 50px;
  margin-left: 25px;
  margin-right: 14px;
}

.people-subtitle {
  font-family: 'nw-primary';
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4d4f53;
  margin:-3px 0px 0px 0px;
}

.people-subtitleNew {
  font-family: 'nw-primary';
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
  margin:-13px 0px 0px 0px;
}

.people-pronouns {
  font-family: "nw-primary";
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
  margin:-11px 0px 0px 0px;
  font-weight: bold;
}

.people-nouns{
  text-align: right;
  font-family: "nw-primary";
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #4d4f53;
}

.people-phone {
  text-align: right;
  font-family: 'nw-primary';
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #4d4f53;
}

.people-email {
  background: #72b36533 0% 0% no-repeat padding-box;
  border: 1px solid #72b365;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  width: 111px;
  height: 24px;
  margin-top: 6px;
}

.people-chat {
  background: #44bce433 0% 0% no-repeat padding-box;
  border: 1px solid #44bce4;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  width: 111px;
  height: 24px;
  margin-top: 6px;
  margin-left: 10px;
}

.peopleexpandLine {
  border-top-width: 1px;
  border-top-color: #707070;
  border-top-style: solid;
  margin-bottom: 10px;
  margin-top: 20px;
}

.sc-gauge {
  old_width: 200px;
  old_height: 200px;
  margin: 10px;
  display: inline-block;
}
.sc-background {
  position: relative;
  height: 100px;
  margin-bottom: 10px;
  background-color: #dee6f2;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  text-align: center;
}
.sc-mask-top {
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  height: 80px;
  background-color: #1c57a5;
  border-radius: 150px 150px 0 0;
}
.sc-mask {
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 150px 150px 0 0;
}
.sc-percentage {
  transform-origin: top center;
  position: absolute;
  top: 100px;
  left: -200%;
  width: 400%;
  height: 400%;
  margin-left: 100px;
  background-color: #1c57a5;
  transition: transform 4s;
}
.sc-min {
  float: left;
}
.sc-max {
  float: right;
}
.sc-value {
  position: absolute;
  top: 69px;
  left: 0;
  width: 100%;
  font-size: 31px;
  line-height: 37px;
  font-weight: 700;
  color: #1c57a5;
}
.sc-value-pct {
  font-size: 18px;
  line-height: 22px;
}
.sc-label {
  font-size: 11px;
  line-height: 13px;
  widows: 100%;
  margin-top: 13px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
}

.fowOverall {
  /* width: 0px;
  background-color: #1c57a5;
  height: 100%;
  transition: width 2s;
  text-align: center; */

  width: 0px;
  background-color: #1c57a5;
  height: 15px;
  transition: width 2s;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 2px;
  border-radius: 12px 0 0 12px;
}
.infoLink {
  border-style: solid;
  border-color: #1c57a5;
  border-width: 1px;
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
  line-height: 13px;
  /* height: 14px;
  width: 14px; */
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  padding: 1px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.portalInfo {
  position: absolute;
  top: 75px;
  left: 23px;
  width: 95%;
  background-color: #dde8ed;
  padding: 25px;
  font-size: 14px;
  background-image: url('/infoBoxTop.png');
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  background-position-y: top;
  /* filter: drop-shadow(3px 3px 4px grey); */
  box-shadow: 0px 3px 6px #00000029;
  color: black;
  font-weight: normal;
  z-index: 100;
}

.portalInfoDown {
  position: absolute;
  top: 40px;
  left: 23px;
  width: 93%;
  background-color: #dde8ed;
  padding: 25px;
  font-size: 14px;
  background-image: url('/infoBoxBottom.png');
  background-repeat: no-repeat;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  background-position-y: bottom;
  color: black;
  font-weight: normal;
  z-index: 100;
}

.search-noresults {
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #1c57a5;
  padding-bottom: 20px;
  padding-top: 30px;
  /* padding-left: 15px; */
  opacity: 1;
}

.searchFilterWords:hover {
  text-decoration: underline;
}

.apslink {
  cursor: pointer;
  text-align: right;
  position: absolute;
  right: 80px;
  text-decoration: underline;
  font-size: 14px;
  font: 'nw-primary';
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  margin-top: 15px;
}

@media (max-width: 575px) {
  .hideMobile {
    display: none !important;
  }
}

.tileButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  display: inline-block;
  background-image: url('/external-link.svg');
  background-repeat: no-repeat;
  background-position-x: 12px;
  background-position-y: center;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0px;
  color: #1c57a5;
  height: 38px;
  line-height: 17px;
  font-family: 'nw-primary';
}

.blueLinkContainer {
  background-color: rgb(222, 230, 242);
  width: 98%;
  display: block;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 10px;
}

.blueLinkInternalContainer {
  background-color: rgb(222, 230, 242);
  width: 100%;
  display: block;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 10px;
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #dde8ed !important;
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  border-bottom-color: #dde8ed !important;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  left: 0% !important;
}
.popover {
  background-color: #dde8ed !important;
  max-width: 350px !important;
}
.popover-body {
  background-color: #dde8ed !important;
  font-size: 14px;
  font-family: 'nw-primary';
}
.popover-header {
  font-size: 14px !important;
  font-weight: bold;
  background-color: #dde8ed !important;
  font-family: 'nw-primary';
  padding-bottom: 0px !important;
}

.LoadResultsHR {
  font-family: 'nw-primary';
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 9px !important;
  margin-left: 295px;
  background: #1c57a5 0% 0% no-repeat padding-box !important;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
}

.noLoadResultsHR {
  font-family: 'nw-primary';
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 9px !important;
  margin-left: 315px;
  background: #1c57a5 0% 0% no-repeat padding-box !important;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
}

@media (max-width: 800px) {
  .LoadResultsHR {
    margin-left: 250px !important;
  }

  .noLoadResultsHR {
    margin-left: 250px !important;
  }
}

@media (max-width: 500px) {
  .LoadResultsHR {
    margin-left: 130px !important;
  }

  .noLoadResultsHR {
    margin-left: 130px !important;
  }
}

@media (max-width: 400px) {
  .LoadResultsHR {
    margin-left: 20px !important;
  }

  .noLoadResultsHR {
    margin-left: 20px !important;
  }
}

@media (max-width: 300px) {
  .LoadResultsHR {
    margin-left: 10px !important;
  }

  .noLoadResultsHR {
    margin-left: 10px !important;
  }
}

@media (max-width: 250px) {
  .LoadResultsHR {
    margin-left: 10px !important;
  }

  .noLoadResultsHR {
    margin-left: 10px !important;
  }
}

.searchText {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 4px;
  /* font: 20px/24px Proxima Nova; */
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #003b5c;
  opacity: 1;
}

backToTop_Org_Image {
  line-height: 22px;
  background-image: url('/bolt-chevron-up.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 20px 11px;
  height: 20px;
  /* width: 20px; */
}

.backToTop_Org_Text {
  font-family: 'nw-primary';
  padding-left: 20px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1c57a5;
}

.backToTop_Org_Text:hover {
  text-decoration: underline;
}

.org-BackToTopContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  padding-top: 40px !important;
  padding-bottom: 20px;
  min-width: 120px;
  min-height: 80px;
  cursor: pointer;
}

.backButtonImage {
  background-image: url('/bolt-chevron-up.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 11px;
  width: 11px;
  height: 12px;
  margin-top: 14px;
  margin-left: 8px;
  transform: rotate(270deg);
}

.expandPeopleOrg {
  color: #1c57a5;
  opacity: 1;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
}

.orgPersonName {
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  padding-top: 13px;
  padding-left: 4px;
}

.orgPersonNameMobile {
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  padding-top: 8px;
  padding-left: 4px;
  margin-right: 8px;
}

.orgPersonTitle {
  font-family: 'nw-primary';
  font-size: 14px;
  color: #4d4f53;
  opacity: 1;
  padding-top: 17px;
  padding-left: 12px;
}
.orgPersonMobile {
  font-family: 'nw-primary';
  font-size: 11px;
  color: #4d4f53;
  opacity: 1;
  margin-top: -18px;
  padding-left: 130px;
  padding-bottom: 6px;
  margin-right: 16px !important;
}

.people-image-org {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  margin-top: 7px;
  width: 41px;
  height: 41px;
  margin-left: 25px;
  margin-right: 14px;
}

.people-org {
  background: #dee6f2 0% 0% no-repeat padding-box;
  border: 1px solid #1c57a5;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1c57a5;
  width: 152px;
  height: 26px;
  margin-top: 6px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 415px) {
  .people-org {
    width: 120px;
  }
}

.people-workGroup {
  background: #dee6f2 0% 0% no-repeat padding-box;
  border: 1px solid #1c57a5;
  border-radius: 6px;
  opacity: 1;
  text-align: left;
  font-family: 'nw-primary';
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1c57a5 !important;
  width: 152px;
  height: 26px;
  margin-top: 6px;
  cursor: pointer;
  /* margin-left: 10px; */
}

.orgChartContainer {
  max-width: 1175px;
  min-height: 400px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin: auto;
}

.orgBackground {
  background-color: #dee6f2;
  width: 100%;
  padding-bottom: 10px;
}

.backButton {
  width: 79px;
  height: 39px;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  margin-left: 12px !important;
  cursor: pointer;
}

.backButtonFont {
  font-family: 'nw-primary';
  font-size: 14px;
  padding-top: 10px;
  padding-left: 5px;
  color: #1c57a5 !important;
  font-weight: bold;
}

.contentOrg {
  padding-left: 35px !important;
  padding-top: 10px;
}

.personBox {
  width: 98%;
  min-height: 55px;
  box-shadow: 0px 4px 8px #0000003e;
  opacity: 1;
  margin-bottom: 12px;
}

.subpersonBox {
  width: 94%;
  margin-left: 35px;
  /* margin-right: 100px !important; */
  min-height: 55px;
  box-shadow: 0px 2px 6px #0000003e;
  opacity: 1;
  margin-bottom: 12px;
}

.expandContainer {
  margin-top: 8px;
  margin-left: 140px !important;
}

.expandContainerMobile {
  margin-top: 8px;
  margin-left: 35px !important;
  margin-right: 19px;
  padding-bottom: 12px;
}

.rowPadding {
  padding-bottom: 7px;
}

.org-line {
  height: 0px;
  width: 96%;
  margin-top: 0px;
  border: 1px solid #bcbdbc;
  margin-bottom: 10px;
}

.org-line2 {
  height: 0px;
  width: 92%;
  margin-top: 0px;
  border: 1px dotted #bcbdbc;
  margin-bottom: 10px;
  margin-top: 10px;
  /* padding-left: -px; */
}

.ASClearbutton {
  margin-left: 250px;
  width: 106px;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border-color: #1c57a5;
  opacity: 1;
  margin-bottom: 20px;
  margin-right: 39px;
}

.backToTop_Org_Image {
  line-height: 22px;
  background-image: url('/bolt-chevron-up.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 20px 11px;
  height: 20px;
  /* width: 20px; */
}

.UseAps {
  font-family: "nw-primary";
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
}

.apslink-noResults {
  color: blue !important;
  text-decoration: underline !important;
  cursor: pointer;
}
