.announcements {
  background-image: url("/AnnounceBackground.png");
  background-color: white;
  background-repeat: repeat-x;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  /* height: 360px; */
  line-height: 24px;
  margin-top: -2px;
  min-height: 250px;
  text-align: left;
  text-size-adjust: 100%;
  width: 100%;
  padding-bottom: 30px;
}
.announcements .previous {
  /* margin-top: 70px; */
  height: 250px;
  /* background-image: url("/previous.png"); */
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-left-thin' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%236d6e71' d='M17.44 28.48l-11.36-11.369h24.098v-2.222h-24.098l11.36-11.369-1.556-1.573-14.062 14.053 14.062 14.053z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  min-height: 68px;
  min-width: 36px;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 0px;
  z-index: 200;
  opacity: 0.1;
}

.announcements .next {
  /* margin-top: 70px; */
  height: 250px;
  /* background-image: url("/next.png"); */
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-right-thin' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%236d6e71' d='M14.56 3.52l11.36 11.369h-24.098v2.222h24.098l-11.36 11.369 1.556 1.573 14.062-14.053-14.062-14.053z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  min-height: 68px;
  min-width: 36px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 0px;
  z-index: 200;
  opacity: 0.1;
}

.announcements .next:hover {
  background-color: white;
  opacity: 0.5;
}
.announcements .previous:hover {
  background-color: white;
  opacity: 0.5;
}

.announcements .displayarea {
  overflow: hidden;
  height: 250px;
  min-height: 200px;
  display: inline-block;
  /* border: solid black 1px; */
  display: block;
  position: absolute;
  align-items: center;
  width: 100%;
  text-align: center;
}

.announcement {
  max-height: 248px;
  max-width: 100%;
  height: 215px;
  display: inline-block;
}

.announcementHeadline {
  font-family: "nw-primary";
  font-size: 32px;
  color: #003b5c;
  font-weight: bold;
  text-align: left;
  line-height: 2.6rem;
  margin-top: 20px;
}
.announcementSummaryText {
  font-family: "nw-primary";
  font-size: 16px;
  color: #4d4f53;
  font-weight: normal;
  text-align: left;
  line-height: 1.1rem;
  min-height: 42px;
}

.announcementBtnBox {
  width: 100%;
  flex-direction: row;
  max-width: 252px;
}

.announcementButton {
  /* top: 272px;
  left: 223px; */

  height: 40px;
  /* font: Bold 14px/17px Proxima Nova; */
  font-family: "nw-primary";
  font-weight: bold;
  font-size: 0.875rem !important;
  line-height: 0.875rem !important;
  letter-spacing: 0px;
  color: #fbfbfb;
  opacity: 1;
  margin-top: 5px;
  min-width: 170px;
}

.announcementButtonSecondary {
  height: 40px;
  font-family: "nw-primary";
  font-weight: bold;
  font-size: 0.875rem !important;
  line-height: 0.875rem !important;
  letter-spacing: 0px;
  color: #326295;
  opacity: 1;
  margin-top: 5px;
  min-width: 170px;
}

@media (max-width: 991px) {
  .announcementButton {
    width: 100%;
  }
  .announcementButtonSecondary {
    width: 100%;
  }
}

@media (min-width: 950px) {
  .announcementButton {
    margin-left: 5px;
    /* width: 230px; */
  }
  .announcementButtonSecondary {
    margin-left: 5px;
    /* width: 230px; */
  }
}

@media (min-width: 1200px) {
  .announcementButtonSecondary {
    margin-left: 3rem;
  }
  .announcementBtnBox {
    margin-top: 32px;
  }
}

.announcementMobileDisplay {
  display: inline-block !important;
  /* overflow: hidden; */
}

.announcementDesktopDisplay {
  display: none !important;
}

/* .announcementImage {
  height: 135px;
} */

.announcementEditContainer {
  border-radius: 12px 12px 0px 0px;
  background-color: white;
  height: 100%;
  display: inline-block;

  text-align: center;
}

.announcementTop {
  border-radius: 12px 12px 0px 0px;
  background-color: white;
  width: 1052px;
  height: 12px;
}

.activeAnnounce {
  background-image: url("/closed_circle.png");
  background-size: 12px 12px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 0px;
}
.inactiveAnnounce {
  background-image: url("/open_circle.png");
  background-size: 12px 12px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 0px;
  cursor: pointer;
}

.announcementCircleContainer {
  position: absolute;
  display: block;
  bottom: -33px;
  left: 50%;
  z-index: 0;
}

.announcementBtn {
  /* padding-top: 93px; */
  max-width: 24px !important;
  /* display: inline-block; */
  position: absolute;
  top: 100px;
  z-index: 0;
  cursor: pointer;
}
@media (max-width: 576px) {
  .announcementImage {
    /* height: 135px; */
    max-width: 100%;
  }
}

.announcementContainer {
  border-radius: 12px 12px 0px 0px;
  background-color: white;
  height: 100%;
  display: inline-block;
  text-align: center;
}
@media (min-width: 993px) {
  .announcementContainer {
    min-height: 260px;
    overflow: hidden;
  }

  .announcementMobileDisplay {
    display: none !important;
  }
  .announcementDesktopDisplay {
    display: inline-block !important;
    width: 95%;
  }
  .announcementBtnBox {
    width: 100%;
    flex-direction: row;
    max-width: 100%;
  }

  .announcementCircleContainer {
    position: absolute;
    display: block;
    bottom: 0px;
    left: 50%;
    z-index: 0;
  }
  .announcementImage {
    /* height: 245px; */
    width: 100%;
  }

  .announcement {
    max-height: 248px;
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
.announcementColumn {
  /* display: inline-block; */
  display: block;
  width: 40%;
  position: absolute;
  top: 0px;
}

.announcementColumnTwo {
  /* display: inline-block; */
  left: 50%;
}
.announcementLabel {
  font-size: 14px;
  font-family: "nw-primary";
}
.announcementCounter {
  font-size: 12px;
  font-family: "nw-primary";
}
.announcementFormHeading {
  font-size: 18px;
  color: #1c57a5;
  font-weight: bold;
}

.announcementBtn:focus {
  outline: none !important;
  outline-offset: none !important;
}
