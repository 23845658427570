.contentContainer {
  margin-top: 20px;
}
.teamContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;

  width: 100%;
  display: block;
  overflow: hidden;
  padding: 3px;
  /* background-image: url("/memberBottom.png");
  background-repeat: repeat-x;
  background-position-y: 79px; */
  /* max-height: 500px; */
  height: 602px;
  /* overflow-y: auto;
  background-color: #206eb6; */
  max-width: 540px;
}
.mobileTeamContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow-x: hidden;
  padding: 3px;
}

.teamContent {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  /* width: 489px; */
  /* height: 100%; */
  min-height: 189px;
  /* overflow-y: hidden; */
  margin: 3px;
  display: block;
  /* height: 451px; */
}

.noScrollingTeam {
  background-color: white;
  margin-right: 5px;
  border-top: 1px solid rgb(188, 189, 188);
}

.scrollingTeam {
  overflow-y: auto;
  height: 463px;
  background-color: white;
  margin-right: 5px;
  border-top: 1px solid rgb(188, 189, 188);
  /* border-top: grey solid 1px; */
  /* padding-top: 30px; */
}
.scrollingTeam::-webkit-scrollbar {
  width: 12px;
}

.scrollingTeam::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollingTeam::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}

#my_team_members {
  position: relative;
}

.teamLeftChevron {
  float: left;
  position: relative;
  left: 0px;
  top: 21px;
  border-radius: 50%;
  z-index: 50;
  height: 32px;
  width: 32px;
}

.teamRightChevron {
  float: right;
  position: relative;
  right: 0px;
  top: -100px;
  border-radius: 50%;
  z-index: 50;
  height: 32px;
  width: 32px;
}

.teamMemberSelect {
  width: 100%;
  height: 90px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.teamMemberSelect .container {
  min-width: 10000px !important;
}

.teamMember {
  width: 250px;
  height: 90px;
  /* border-right: 1px solid gray; */
  display: inline-block;
  overflow: hidden;
  /* outline: none!important; */
  color: black !important;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.tmactive {
  border-top: 10px solid #5bc6e8;
  background-image: url('/activeMemberBottom.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: white;
}

.tminactive {
  border-top: 10px solid transparent;
  background-image: url('/inactiveMemberBottom.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: white;
}
.teamMemberData {
  display: inline-block;
  /* border-right: 1px solid #d3d3d3; */
  width: 175px;
  height: 115px;
  /* overflow: hidden; */
  margin-top: 6px;
  font-size: 18px;
  text-align: left;
  color: #1c57a5;
  font-weight: bold;
  vertical-align: top;
}
.teamMemberPronouns {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4d4f53;
}
.teamMemberPhone {
  font-size: 11px;
  font-weight: bold;
  padding-left: 0px;
}
.teamMemberPhoneNumber {
  font-size: 14px;
  font-weight: normal;
}
.teamContactButtonContainer {
  width: 100%;
  display: block;
  padding-left: 0px;
  border-bottom: #1c57a5 dashed 1px;
  padding-bottom: 12px;
  padding-top: 12px;
}
.teamMemberFow {
  border-bottom: #1c57a5 dashed 1px;
}
.teamMemberRequiredLearning {
  width: 100%;
  display: block;

  /* border-bottom: gray dashed 1px; */
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 14px;
  color: #4d4f53;
}
.teamMemberLearning {
  width: 100%;
  display: grid;
  grid-template-columns: 250px 88px;
  border-top: #bcbdbc solid 1px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.teamMemberLearningTitle {
  color: #4d4f53;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  opacity: 1;
  /* display: inline-block; */
  /* width: 250px; */
  /* vertical-align: top; */
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 10px;
}
.teamMemberLearningStatus {
  color: #4d4f53;
  font-size: 11px;
  vertical-align: top;
  text-align: left;
  opacity: 1;
  /* display: inline-block; */
}
.learningPastDue {
  background-image: url('/pastdue.png');
  background-repeat: no-repeat;
  padding-left: 34px !important;
  padding-top: 8px;
  height: 35px;
}
.learningIncomplete {
  background-image: url('/incomplete.png');
  background-repeat: no-repeat;
  padding-left: 34px !important;
  padding-top: 8px;
  height: 35px;
}
.learningComplete {
  background-image: url('/completed.png');
  background-repeat: no-repeat;
  padding-left: 34px !important;
  padding-top: 8px;
  height: 35px;
}
.teamMemberPosition {
  color: gray;
  font-size: 14px;
}
.myTeamQuickLinks {
  padding-top: 12px;
  font-size: 11px;
  font-weight: bold;
  padding-left: 0px;
  border-bottom: gray dashed 1px;
  padding-bottom: 12px;
}
.teamMemberImageContainer {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  margin-top: 8px;
}

.memberTouchbase {
  background-image: url('/calendar.svg');
  background-repeat: no-repeat;
  display: block;
  height: 20px;
  padding-left: 30px;
  font-size: 14px;
}

.teamMemberContactControls {
  display: flex;
  padding-left: 40px;
}

.teamMemberChevronContainer {
  display: inline-block;
  overflow: hidden;
  height: 54px;
  width: 30px;
  padding-top: 10px;
}

.teamMemberImage {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  border: lightgrey solid 1px;
}

.myTeamCount {
  background-image: url('/team_icon.png');
  background-repeat: no-repeat;
  color: white;
  text-align: left;
  margin-left: 200px;
  padding-left: 30px;
  margin-top: 10px;
}

.teamMemberButton {
  /* width: 100%; */
  text-align: left;
  border: none;
  background-color: white;
}

.chatButton {
  background-image: url('/chat.svg');
  background-repeat: no-repeat;
  background-position: 20px;
  padding-left: 22px !important;
  padding-top: 1px !important;
  background-color: #daf2fa !important;
  border: 1px solid #44bce4 !important;
  border-radius: 6px;
  height: 24px;
  width: 103px;
  font-family: 'nw-primary' !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
}
.emailButton {
  background-image: url('/email.svg');
  background-repeat: no-repeat;
  background-position: 15px;
  padding-left: 24px !important;
  height: 24px;
  width: 136px;
  padding-top: 1px !important;
  background-color: #e3f0e0 !important;
  border: 1px solid #72b365 !important;
  border-radius: 6px;
  font-family: 'nw-primary' !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
}

.fowContainer {
  padding-top: 15px;
}

.loadMoreButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  display: inline-block;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0px;
  color: #1c57a5;
  height: 38px;
  line-height: 17px;
  font-family: 'nw-primary';
}

.fowDashboardButton {
  margin-left: 70px;
  width: 157px;
  padding-left: 30px;
}

.requiredTrainingButton {
  margin-left: 30px;
  width: 160px;
  padding-left: 30px;
}

.teamIndent {
  color: #1c57a5;
  margin-left: 5px;
}
.teamDisplay {
  padding-bottom: 15px;
  padding-left: 5px;
}

.fowBar {
  /* width: 90%; */
  border-color: #1c57a5;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  height: 20px;
  overflow: hidden;
  /* margin-left: auto; */
  margin-right: auto;
  background-color: #dee6f2;
  display: inline-block;
  width: 184px;
}

.fowCompletionBarAndPct {
  display: inline-block !important;
}

.noTeamButton {
  border-radius: 6px;
  opacity: 1;
  height: 48px;
  width: 48px;
  padding-top: 8px;
  padding-left: 8px;
  background-image: url('/team_icon.png');
  background-color: #dee6f2;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.teamGoalProgress {
  color: #4d4f53;
  font-weight: bold;
  font-size: 14px;
  padding-top: 8px;
}
.teamCompletionPct {
  display: inline-block;
  font-size: 18px;
  color: #1c57a5;
  font-weight: bold;
  padding-left: 5px;
}
.teamFowMessage {
  font-size: 12px;
  color: #0c3b5c;
}

.teamLearningRow {
  width: 100%;
  display: grid;
  padding-top: 5px;
  grid-template-columns: 170px 130px 130px;
}
.teamLearningColumn1 {
  margin-top: auto;
  margin-bottom: auto;
}
.teamLearningColumnOther {
  /* width: 130px !important;
  display: inline-block; */
}

.teamCongratulations {
  background-image: url(/congratulations.png);
  background-repeat: no-repeat;
  background-position: 8px;
  padding-left: 52px;
  border: #58c140 solid 1px;
  border-radius: 6px;
  background-color: #d5efcf;
  width: 100%;
  min-height: 40px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.teamCongratulationsHeading {
  color: #0c3b5c;
  font-size: 11px;
}
.teamCongratulationsItem {
  color: #0c3b5c;
  font-size: 14px;
  font-weight: bold;
}
.fowSubTitle {
  color: rgb(28, 87, 165);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.fowText {
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  width: 90%;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: 11px;
  letter-spacing: 0.48px;
  color: #0c3b5c;
  text-transform: uppercase;
  opacity: 1;
}

.fowOverallPct {
  float: right;
  color: #1c57a5;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.team-tile-header {
  color: #1c57a5;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  /* padding-left: 25px; */
  padding-top: 10px;
  padding-bottom: 9.23px;
  font-family: 'nw-primary';
}

.memberDevPlan {
  background-image: url('/plan.svg');
  background-repeat: no-repeat;
  background-position: 15px;
  font-family: 'nw-primary' !important;
  font-weight: bold !important;
  font-size: 14px !important;
  display: block;
  height: 25px;
  padding-left: 22px !important;
  padding-top: 1px !important;
  font-size: 14px;
  margin-top: 13px;
  margin-bottom: 2px;
  background-color: #d2dded !important ;
  border: 1px solid #1c57a5 !important;
  border-radius: 6px;
  color: #1c57a5 !important;
  height: 24px;
  width: 254px;
  margin-left: 15px;
}

.teamsTitle {
  font-size: 22px;
  height: 38px;
  padding-left: 25px;
}
.teamsExpand {
  font-size: 14px;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  color: #1c57a5;
  margin-right: 38px;
}

@media (min-width: 400px) {
  /* .requiredTrainingButton {
    margin-left: 65px;
  } */
  .fowBar {
    width: 228px;
  }
  /* .fowDashboardButton {
    margin-left: 120px;
  } */
  .teamMemberData {
    width: 200px;
    height: 95px;
  }
}
@media (min-width: 450px) {
  /* .requiredTrainingButton {
    margin-left: 115px;
  } */
  .fowBar {
    width: 275px;
  }
  .teamMemberPhone {
    padding-left: 74px;
  }
  .teamContactButtonContainer {
    padding-left: 59px;
  }
  .myTeamQuickLinks {
    padding-left: 66px;
  }
  .teamMemberData {
    width: 245px;
    height: 95px;
  }
}

@media (min-width: 475px) {
  .fowBar {
    width: 310px;
  }
}
@media (min-width: 500px) {
  /* .requiredTrainingButton {
    margin-left: 158px;
  } */
  /* .fowDashboardButton {
    margin-left: 212px;
  } */
  .fowBar {
    width: 320px;
  }
  .teamMemberData {
    width: 295px;
    height: 70px;
  }
}

@media (min-width: 515px) {
  .teamLearningColumn1 {
    width: 170px;
  }
  .mobileTeamContainer {
    min-width: 515px;
  }
  .teamContainer {
    min-width: 515px;
  }
}

@media (min-width: 576px) {
  .fowCompletionBarAndPct {
    width: 80%;
  }
  /* .fowDashboardButton {
    margin-left: 237px;
  } */
  /* .requiredTrainingButton {
    margin-left: 185px;
  } */
  .teamIndent {
    margin-left: 25px;
  }
  .teamDisplay {
    padding-left: 30px;
  }
  .teamMemberData {
    width: 320px;
  }
}

@media (min-width: 775px) {
  /* .fowDashboardButton {
    margin-left: 261px;
  } */
  /* .requiredTrainingButton {
    margin-left: 208px;
  } */
}
