.approval {
  display: block;
  width: 100%;
}

.approvalTypeLabel {
  font-size: 11px;
  font-weight: bold;
  color: #1c57a5;
  display: inline-block;
  width: 55px;
  margin-right: 33px;
}

.approvalTitleLabel {
  font-size: 11px;
  font-weight: bold;
  color: #1c57a5;
  display: inline-block;
  width: 78px;
  padding-left: 40px;
}
.approvalType {
  font-size: 14px;
  color: #4d4f53;
  display: inline-block;
  width: 55px;
  margin-right: 33px;
  vertical-align: top;
}

.approvalTitle {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  width: 180px;
  color: #4d4f53;
  padding-left: 41px;
}

.snowTitle {
  background-image: url('/approvalsSNOW.png');
  background-repeat: no-repeat;
  background-position: top left;
}

.iiqTitle {
  background-image: url('/approvalsIIQ.png');
  background-repeat: no-repeat;
  background-position: top left;
}
.otTitle {
  background-image: url('/approvalsOT.png');
  background-repeat: no-repeat;
  background-position: top left;
}
.aribaTitle {
  background-image: url('/approvalsARIBA.png');
  background-repeat: no-repeat;
  background-position: top left;
}
.approvalButtons {
  display: inline-block;
  color: #1c57a5 !important;
  text-decoration: underline;
  border: transparent solid 1px;
  background-color: transparent;
  font-family: 'nw-primary';
}
.approvalButtonSpacing {
  display: inline-block;
}

.approvalButtons:hover {
  border: #1c57a5 solid 1px;
  text-decoration: underline;
}

.serviceNowButton {
  margin-left: 1px;
  width: 125px;
  /* height: 38px; */
  padding-left: 30px;
}
.aribaButton {
  margin-left: 2px;
  width: 78px;
  /* height: 38px; */
  display: inline-block;
  padding-left: 30px;
}

.iiqButton {
  margin-left: 2px;
  width: 64px;
  /* height: 38px; */
  display: inline-block;
  padding-left: 30px;
}

.concurButton {
  margin-left: 12px;
  width: 94px;
  /* height: 38px; */
  display: inline-block;
  padding-left: 30px;
}

.actionsContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 3px;
  height: 525px;
  max-width: 540px;
  margin-top: 20px;
}

.mobileActionsContainer {
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  display: block;
  overflow-x: hidden;
  padding: 3px;
  margin-top: 20px;
}

.actionsContent {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  min-height: 189px;
  /* overflow-y: auto; */
  margin: 3px;
  display: block;
}

.noScrollingActions {
  background-color: white;
  margin-right: 5px;
  position: relative;
  border-top: 1px solid rgb(188, 189, 188);
}

.scrollingActions {
  overflow-y: auto;
  height: 394px;
  background-color: white;
  margin-right: 5px;
  position: relative;
  border-top: 1px solid rgb(188, 189, 188);
}

.scrollingActions::-webkit-scrollbar {
  width: 12px;
}

.scrollingActions::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollingActions::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}
.approval-refresh {
  display: block;
  cursor: pointer;
  font-family: 'nw-primary';
  float: right;
  margin-right: 25px;
}

.approvalsConfirmDialog {
  position: absolute;
  /* top: 50%; */
  background-color: white;
  border: #1c57a5 solid 1px;
  border-radius: 12px;
  left: 5%;
  width: 80%;
  padding: 15px;
  z-index: 100;
}

.modal-content {
  border: #1c57a5 solid 1px;
  border-radius: 12px !important;
}

.approvalActionCover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: gray;
  display: block;
  z-index: 50;
}
.approvalDialogTitle {
  font-size: 14px;
  color: #4d4f53;
}
@media (min-width: 400px) {
  .serviceNowButton {
    margin-left: 15px;
  }
  .aribaButton {
    margin-left: 12px;
    width: 83px;
  }

  .iiqButton {
    margin-left: 12px;
    width: 64px;
  }
}
@media (min-width: 450px) {
  .approvalTitle {
    width: 250px;
  }
  .serviceNowButton {
    margin-left: 60px;
  }
}
@media (min-width: 500px) {
  .approvalTitle {
    width: 300px;
  }
  .serviceNowButton {
    margin-left: 115px;
  }
}
@media (min-width: 576px) {
  .approvalTitle {
    width: 100%;
  }
  .serviceNowButton {
    margin-left: 166px;
  }
}

@media (min-width: 765px) {
  .serviceNowButton {
    margin-left: 192px;
  }
}

@media (min-width: 1200px) {
  .actionsContainer {
    margin-top: 0px;
  }
}
